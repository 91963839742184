<template>
  <div style="width: 100%">
    <!-- Loader -->
    <v-skeleton-loader
      v-if="!ready"
      type="image, list-item"
      width="100%"
    ></v-skeleton-loader>
    <!-- Graph -->
    <apexchart
      v-else
      width="100%"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'barGraph',

  props: {
    hint: {type: String, default: "Nombre d'enregistrements"},
    headers: { type: Array, default: [] },
    values: { type: Array, default: [] }
  },

  computed: {
    ready() {
      return this.values.length && this.headers.length
    },

    options() {
      // options for apexcharts
      return {
        chart: {
          id: 'bar-graphic',
          toolbar: {
            show: false
          }
        },
        annotations: {
          points: [
            {
              x: '2020-11-19',
              seriesIndex: 0,
              label: {
                offsetY: -10,
                style: {
                  color: 'white',
                  background: 'var(--v-primary-base)'
                },
                text: 'Lancement'
              }
            }
          ]
        },
        xaxis: {
          labels: {
            // rotate: 45
          },
          categories: this.headers,
          tickPlacement: 'on'
        },
        yaxis: {
          labels: {
            formatter: v => {
              return v.toFixed(0)
            }
          }
        },
        colors: ['var(--v-primary-base)'],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 10,
          style: {
            fontSize: '12px',
            colors: ['white']
          },
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 2,
            color: 'var(--v-primary-base)',
            opacity: 1
          }
        }
      }
    },
    series() {
      return [
        {
          name: this.hint,
          data: this.values
        }
      ]
    }
  }
}
</script>
